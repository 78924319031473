import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import LinkedinIcon from '../vendors/fontAwesome/svgs/linkedin-in.svg';
import GithubIcon from '../vendors/fontAwesome/svgs/github.svg';

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact</h1>
    <p>Have a project? A question?</p>
    <p>Feel free to contact me via the below or drop me an <a href="mailto:eileen.lin78@gmail.com">email</a>!</p>
    <div className="contact-btn-box">
      <a className="contact-a" href="https://www.linkedin.com/in/lineileen">
        <div className="contact-btn-container button button--secondary">
          <div><LinkedinIcon className="icon--contact" /></div>
          <div className="contact-btn-text">lineileen</div>
        </div>
      </a>

      <a className="contact-a" href="https://github.com/minteapixel">
      <div className="contact-btn-container button button--secondary contact-btn-margin">
        <div><GithubIcon className="icon--contact"/></div>
        <div className="contact-btn-text">minteapixel</div>
      </div>
      </a>
    </div>
  </Layout>
)

export default Contact
